import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/logincustom.css"; 
import Swal from 'sweetalert2';

function AuthPage({ setUser }) {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullname, setFullName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [alertMessage, setAlertMessage] = useState('');
  const [alertColor, setAlertColor] = useState('');
  const navigate = useNavigate();
  
  const url = `${process.env.REACT_APP_API_URL}`;
  console.log("url : ",url)

  // useEffect เพื่อทำงานเมื่อ component ถูกเรนเดอร์
  useEffect(() => {
    // คุณสามารถทำการตั้งค่าเริ่มต้นหรือเรียก API ได้ที่นี่
    console.log('Component mounted or updated');
    
    // ตัวอย่างการรีเซ็ตสถานะ
    if (isLogin) {
      setEmail("");
      setPassword("");
    } else {
      setFullName("");
      setConfirmPassword("");
    }
  }, [isLogin]); // ใช้ dependancy array เพื่อให้ฟังก์ชันนี้ทำงานเมื่อ isLogin เปลี่ยนแปลง

  const handleToggle = () => {
    setIsLogin(!isLogin);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${url}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        Swal.fire({
          icon: 'error',
          title: 'ชื่อหรือรหัสผ่านไม่ถูกต้อง'
        });
        return;
      }

      const data = await response.json();
      if (data.success) {
        setUser({ email, fullname: data.fullname });
        navigate("/booking");
      } else {
        Swal.fire({
          icon: 'error',
          title: data.message || "Invalid credentials"
        });
      }
    } catch (error) {
      console.error("Error during login:", error);
      alert(error.message);
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    //console.log('vapass',validatePasswordMatch)
    console.log("url : ",url)
    const checkpass = validatePasswordMatch(password,confirmPassword)
    console.log('checkpass',checkpass);
    try {
      if(checkpass === 200 ){ 
        const response = await fetch(
          `${url}/register`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ fullname, email, password , confirmPassword}),
          }
        );
        
        const data = await response.json();

        console.log("API Response:", data.statusCode);
        if (data.statusCode === 409) {
          Swal.fire({
            icon:  'error',
            title : 'มีemailอยู่แล้ว'
          })
          throw new Error("Network response was not ok");
        }   
  
        //const data = await response.json();
        console.log("API Response:", data.statusCode);
        if (data.statusCode) {
          Swal.fire({
            icon: 'success',  
            title : 'User registered successfully'
          })
          //alert("Registration successful! You can now log in.");
          handleToggle(); 
        } else {
          //alert(data.message || "Registration failed");
          Swal.fire({
            icon: 'error',
            title: 'ERROR'
          })
        }
        // 200 do register 
      }else{
        // error
        Swal.fire({
          icon : 'error',
          title : 'Password not Match'
        })
      }
    } catch (error) {
      console.error("Error during registration:", error);
    }
  };

  const validatePasswordMatch = (password, confirmPassword) => {
    let status
    if(!password && !confirmPassword){
      setAlertMessage('');
      return;
    }
    if(password.length<8){
      setAlertMessage('Password must be at least 8 characters.');
      setAlertColor('red');
      status = 400;
    }else if (password !== confirmPassword) {
      setAlertMessage('Passwords do not match');
      setAlertColor('red');
      status = 400;
    } else {
      setAlertMessage('Passwords match.');
      setAlertColor('green');
      status = 200;
    } 
    return status;
  };

  const handlePasswordChange = (e) => {
    const inputPassword = e.target.value;
    setPassword(inputPassword);
    validatePasswordMatch(inputPassword, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const inputConfirmPassword = e.target.value;
    setConfirmPassword(inputConfirmPassword);
    validatePasswordMatch(password, inputConfirmPassword);
  };

  return ( 
    <div className="custom-container">
        <div className="col-md-6 custom-card-container">
          <div className="card-body custom-card-body">
            <h3 className="card-title text-center">
              {isLogin ? "Login" : "Register"}
            </h3>
            <form onSubmit={isLogin ? handleLogin : handleRegister}>
              {!isLogin && (
                <div className="form-group custom-form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="fullname"
                    placeholder=" "
                    value={fullname}
                    onChange={(e) => setFullName(e.target.value)}
                    required
                  />
                  <label htmlFor="full-name">Full name</label>
                </div>
              )}
              <div className="form-group custom-form-group">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder=" "
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <label htmlFor="email">Email</label>
              </div>
              {isLogin && (
                <div className="form-group custom-form-group">
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder=" "
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <label htmlFor="password">Password</label>
                </div>
              )}
              {!isLogin && (
                <div className="form-group custom-form-group confirm-passsword">
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder=" "
                    value={password}
                    onChange={handlePasswordChange}
                    required
                  />
                  <label htmlFor="password">Password</label>
                </div>
              )}
              {!isLogin && (
                <div className="form-group custom-form-group">
                  <input
                    type="password"
                    className="form-control"
                    id="confirm-password"
                    placeholder=" "
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    required
                  />
                  <label htmlFor="confirm-password">Confirm password</label>
                  <p style={{ color: alertColor, textAlign: "center" }}>{alertMessage}</p>
                </div>
              )}
              <div className="text-center mt-3">
                {isLogin && (
                  <a href="/forgot-password" className="custom-link">
                    Forgot Password?
                  </a>
                )}
                <p className="mt-2">
                  {isLogin
                    ? "Don't have an account?"
                    : "Already have an account?"}{" "}
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={handleToggle}
                  >
                    {isLogin ? "Register" : "Login"}
                  </button>
                </p>
                <button type="submit" className="btn no-hover btn-block">
                  {isLogin ? "Login" : "Register"}
                </button>
              </div>
            </form>
          </div>
        </div>
    </div>
  );
}

export default AuthPage;
