import React, { useState } from 'react';
import {
    Routes,
    Route
} from "react-router-dom";
import MainPage from './components/MainPage';
import LoginPage from './components/LoginPage';
import BookingPage from './components/BookingPage';
import ForgotpasswordPage from './components/ForgotPage';
import ResetPasswordPage from './components/ResetPage';

function App() {
    const [user, setUser] = useState({}); // ใช้ useState เพื่อเก็บข้อมูลผู้ใช้

    return (
        <div>
            <Routes>
                <Route path="/" element={<MainPage user={user} />} />
                <Route path="/login" element={<LoginPage setUser={setUser} />} />
                <Route path="/booking" element={<BookingPage user={user} />} />
                <Route path="/forgot-password" element={<ForgotpasswordPage />} />
                <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
            </Routes>
        </div>
    );
}

export default App;
