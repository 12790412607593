import React , {useState , useEffect} from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-modal'
import '../styles/BookingPage.css'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const Calendar  = () =>  {
  const [events , setEvents] = useState ([]);
  const [room ,setEventRoom] = useState('');
  const [details , setEventDetails] = useState('');
  const [startDateTime , setEventstartDateTime] =useState('');
  const [endDateTime , setEventEndDateTime] = useState('');
  const [seat , setEventSeat] = useState('');
  const [message , setEventMessage] = useState('');
  const [currentTime, setCurrentTime] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isRoomModalOpen , setIsRoomModalOpen] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isShowModalOpen , setIsShowModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [data, setData] = useState(null);
  
  const url = `${process.env.REACT_APP_API_URL}`;
  console.log("url : ",url)

  const navigate = useNavigate('');


  const handleDateClick = (arg) => {
    setSelectedDate(arg.dateStr);
    setIsRoomModalOpen(true);
    //setIsAddModalOpen(true);
  };

  const handleAddEvent = async (event) =>{  
      event.preventDefault();
    try{    
        const response = await fetch(`${url}/booking`,{
          method:'POST',
          headers:{
            'content-type':'application/json',
          },
          body:JSON.stringify({
            room: selectedRoom,
            startDateTime: new Date(startDateTime).toISOString(), 
            endDateTime: new Date(endDateTime).toISOString(), 
            seat,
            details , 
          }),
        });


        if (!response.ok) {
          Swal.fire({
            icon :'error',
            title :'เวลาซ้อนทับ'
          })
          //console.error('HTTP Error:', response.status);
          return;
        }
        //console.log(response);
        console.log('Respones status' , response.status);
        console.log('1');
        const data  = await response.json();
        console.log('Respones Text;', data);
  
        console.log('2');
        Swal.fire({
          title:'จองห้องแล้ว',
          icon:'success'
        })
        setIsAddModalOpen(false);
    }catch (error){
      console.log('Error', error);
      setEventMessage('An error occurred');
    }
  };


  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(`${url}/book`); 
        const data = await response.json();

        
        const formattedEvents = data.map((booking) => ({
          id: booking.id,
          title: booking.room,
          start: booking.start, 
          end: booking.end, 
          extendedProps: {
            details: booking.details
          }
        }));

        
        setData(formattedEvents);
        setEvents(formattedEvents); // อัปเดต events
      } catch (error) {
        console.error('Error fetching bookings:', error);
      }
    };

    fetchEvents();
  }, []);


  const handleEventClick = (clickInfo) => {
    console.log('Event clicked:', clickInfo.event);
    const { id, title, start, end, extendedProps } = clickInfo.event;
    setSelectedEvent({ id, title, start, end, details: extendedProps.details });
    setEventRoom(title);
    setEventstartDateTime(start.toISOString());
    setEventEndDateTime(end.toISOString());
    setEventDetails(extendedProps.details);
    //setIsAddModalOpen(true);
    //setIsEditModalOpen(true);
    setIsShowModalOpen(true);
    
    
  };


const handleUpdateEvent = async (event) => {
    event.preventDefault();

    console.log('Selected Event:', selectedEvent);

if (!selectedEvent || !selectedEvent.id) {
  console.error('Selected event or event ID is missing');
  return;
}

console.log('SEV',selectedEvent.id)

    try {
      const response = await fetch(`${url}/Edit/${selectedEvent.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          room,
          startDateTime: new Date(startDateTime).toISOString(),
          endDateTime: new Date(endDateTime).toISOString(),
          seat,
          details
        }),
      });
      if (!selectedEvent || !selectedEvent.id) {
        console.error('Selected event or event ID is missing');
        return;
      }

      console.log('bodyroom',room)

      console.log('1')
      if (!response.ok) {
        console.error('HTTP Error:', response.status);
        return;
      }

      const updatedEvents = events.map((e) =>
        e.id === selectedEvent.id
          ? {
              ...e,
              title: room,
              start: startDateTime,
              end: endDateTime,
              seat,
              extendedProps: { details }
            }
          : e
      );

      setEvents(updatedEvents);
      setIsEditModalOpen(false);
    } catch (error) {
      console.log('Error', error);
    }
  };


  const checkDateStatus = (startDateTime)=> {
    const inputDate = new Date (startDateTime);
    const time = new Date ();

    if(inputDate < time){
      return('pass')
    }else{
      const diffTime = Math.abs(inputDate - time);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      return diffDays <= 2 ? 'upcoming' : 'future';
    }
  }


  const handleEventRender =(info) =>{
    const eventStatus =checkDateStatus (info.event.start);

    if(eventStatus === 'pass'){
      info.el.style.backgroundColor = '#A32029';
      info.el.style.borderColor = '#A32029';
    }else if(eventStatus ==='upcoming'){
      info.el.style.backgroundColor ='#F2B705';
      info.el.style.borderColor =  '#F2B705';
    }else{
      info.el.style.backgroundColor = '#9AEBA3';
      info.el.style.borderColor = '#9AEBA3';
    } 
  };





  const formatTime = (date) => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };  

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
},[]);



  const handleLogout = () => {
    //localStorage.removeItem('authToken'); ลบข้อมูลคีย์ที่เก็บใน Local Storage
    Swal.fire({
      title:'ออกจากระบบแล้ว',
      icon: "success"
    });
    navigate('/login');
  };

  const handleRoomClick = (roomNumber) => {
    console.log(`Room ${roomNumber} clicked`);
    setSelectedRoom(roomNumber); // เปิดโมดัลที่แตกต่างตาม roomNumber
    setIsAddModalOpen(true);
  };
  

  return(
    <div className='mainpage'>

      <nav class="navbar navbar-expand navbar-light bg-faded  bg-light navbar-light ">
        <div class="container">
          <div className='nav navbar-nav logo '></div>
          {/* <nav class="navbar-brand logo-custom  mb-5  "></nav>  */}
          <ul class="navbar-nav me-auto mt-2 mt-lg-0">
            <li className='nav-item'>
            </li>
          </ul>
          <form class="d-flex my-2 my-lg-0 align-item-center justify-content-between">
            <button type='button'  onClick={handleDateClick} className='btn btn-primary btn-md'>จองห้อง</button>
            <button  onClick={handleLogout} className='btn btn-danger mx-4 btn-md'>Logout</button> 
            <div className='mt-1 d-none d-md-block'>{formatTime(currentTime)}</div>
          </form>
        </div>
      </nav>
      
      
      <div className='container'>
      <br></br>
      <br></br>
      <br></br>
        <div className='row'>

        <div className=' col-xs-12 col-sm-12 col-md-12 col-lg-8   calendar-css-container' >
            <div className=''></div>
            <FullCalendar
              plugins={[ timeGridPlugin, interactionPlugin ]}
              events={events}
              eventClick = {handleEventClick}
              eventDidMount={handleEventRender} 
            />
          </div>

          <div className=' view col-xs-12 col-sm-12 col-md-12 col-lg-4' >
            <div className='mini-calendar'>
              <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              //googleCalendarApiKey="T3jL6kK1iV6aA5nR6dF4tX3lS7jD4gT7jR8aR3sV1iS7dL8jA5"
              events={events}
              eventClick = {handleEventClick}
              eventDidMount={handleEventRender}
              editable={true}
              selectable={true}
              selectMirror={true}
              />
            </div>
            <div className='list-view '>
            <FullCalendar
                    plugins={[listPlugin, interactionPlugin,timeGridPlugin]}
                    initialView="listWeek" 
                    events={events}
                    eventClick = {handleEventClick}
                    
            />
            </div>            
          </div>
          
          
        </div>
      </div>
    
      <div>
        <Modal
          isOpen={isRoomModalOpen}
          onRequestClose={() => setIsRoomModalOpen (false)}
        >
          <div className='form-room-event'>
            <div className='row'>
              <div className='col-4'>
                
                <div
                  className='formRoom room1'
                  onClick={() => handleRoomClick('Room 1')} 
                ></div>
              </div>
              <div className='col-4'>
                <div
                className='formRoom room2'
                onClick={() => handleRoomClick('Room 2')}
                ></div>
              </div>
              <div className='col-4'>
                <div className='formRoom room3'
                onClick={() => handleRoomClick('Room 3')}
                ></div>
              </div>
              
            </div>
          </div>
        </Modal>
      </div>
      <div>
            <Modal 
          isOpen={isAddModalOpen}
          onRequestClose={() => setIsAddModalOpen(false)}
          >
            <div className='from-add-event'>
              <div value ={selectedRoom} onChange={(e) => setEventRoom(e.target.value)}>
                <p>You have selected: {selectedRoom}</p>
              </div>
              
              <div className=''>
                <label >details:</label>
                <textarea className='inputvalue'
                type='text'
                value={details}
                onChange={e => setEventDetails(e.target.value)}></textarea>
              </div>
              <br></br>
              <div className=' '>
                <label>start Time:</label>
                  <input
                  className='inputvalue'
                  type='datetime-local'
                  value={startDateTime}
                  onChange={e => setEventstartDateTime(e.target.value)}></input>
              </div>
              <br></br>
              <div className=''>
                <label>end Time:</label>
                  <input
                  className='inputvalue'
                  type='datetime-local'
                  value={endDateTime}
                  onChange={e => setEventEndDateTime(e.target.value)}></input>
              </div>
              <div className=''>
                <label>meeting attendess</label>
                  <input 
                  className='inputvalue'
                  type='INTEGER'
                  value={seat}
                  onChange={e=> setEventSeat(e.target.value)}></input>
              </div>
              <div className=''>
                <button  onClick={handleAddEvent}>Add Event</button>
                <button onClick={() => setIsAddModalOpen(false)}>Cancel</button>
              </div>
            </div>
            
          </Modal>
      
      </div>   
          <Modal
            isOpen={isShowModalOpen}
            onRequestClose={() => setIsShowModalOpen(false)}
          > 
            <form onSubmit={''}>
                <label>Form Show details</label>
                {data ? (
          <div>
            <div className='row'>
              <div className='col-12'>
                <p>Room: {data.room}</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-6'>
                <p>Start Time: {data.startDateTime}</p>
              </div>
              <div className='col-6'>
                <p>End Time: {data.endDateTime}</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
              <p>Details: {data.details}</p>
              </div>
            </div>
          </div>
        ) : (
          <p>Loading...</p>
        )}
        <div className='row'>
          <div className='col-6'>
            <button type='button' onClick={setIsEditModalOpen}>Edit</button>
          </div>
          <div className='col-6'>
            <button type='button' onClick={()=>setIsShowModalOpen(false)}>Close</button>
          </div>
        </div>
            </form>
          </Modal>

      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={() => setIsEditModalOpen(false)}
      >
        <form onSubmit={handleUpdateEvent}>
          <label>Form Edit</label>
          <div>
            <label>Room:</label>
            <select value={room} onChange={(e) => setEventRoom(e.target.value)}>
                <option value="Room 1">Room 1</option>
                <option value="Room 2">Room 2</option>
                <option value="Room 3">Room 3</option>
            </select>
          </div>
          <div>
            <label>Start Time:</label>
            <input
              type="datetime-local"
              value={startDateTime}
              //value={new Date(startDateTime).toISOString().slice(0, 16)}
              onChange={(e) => setEventstartDateTime(e.target.value)}
            />
          </div>
          <div>
            <label>End Time:</label>
            <input
              type="datetime-local"
              value={endDateTime}
              //value={new Date(endDateTime).toISOString().slice(0, 16)}
              onChange={(e) => setEventEndDateTime(e.target.value)}
            />
          </div>
          <div>
            <label>Details:</label>
            <textarea
              value={details}
              onChange={(e) => setEventDetails(e.target.value)}
            />
          </div>
          <div className=''>
                <label>meeting attendess</label>
                  <input 
                  type='INTEGER'
                  value={seat}
                  onChange={e=> setEventSeat(e.target.value)}></input>
              </div>
          <button type="submit" onClick={handleUpdateEvent}>Update Event</button>
          <button type="button" onClick={() => setIsEditModalOpen(false)}>Cancel</button>
          
        </form>
      </Modal>
        </div>
  )
}

export default Calendar;

