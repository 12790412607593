import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../styles/forgot.css";

function ForgotPasswordPage() {
  const url = `${process.env.REACT_APP_API_URL}`;
  console.log("url : ",url)

  const [email, setEmail] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleForgotPassword = async (event) => {
    event.preventDefault();

    try {
      console.log("API URL:", url);

      const response = await fetch(`${url}/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      console.log("Received email:", email);
      console.log("Response status:", response.status);
      console.log("Response body:", await response.text());

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Response JSON:", data);

      if (data.success) {
        setIsSuccess(true);
        setError("");
        alert("Password reset instructions have been sent to your email.");
        navigate("/login");
      } else {
        setError(data.message || "Error processing request");
      }
    } catch (error) {
      console.error("Error during password reset:", error);
      setError("An error occurred while resetting the password.");
    }
  };

  return (
    <div className="custom-container">
      <div className="custom-card-container">
        <div className="bg4 d-none d-md-block"></div> 

        <div className="custom-card-body">
          <h1 className="header mb-4">Forgot Password</h1>
          <form onSubmit={handleForgotPassword}>
            <div className="custom-form-group mb-3">
              <input
                type="email"
                id="email"
                placeholder=""
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="email">Email</label>
            </div>
            <button type="submit" className="btn custom-button btn-block mb-3">
              Reset Password
            </button>

            {isSuccess && (
              <div className="alert alert-success" role="alert">
                Success! Password reset link has been sent to your email.
              </div>
            )}

            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}

            <p className="text-center">
              <Link className="btn-link" to="/login">Back To Login</Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordPage;
