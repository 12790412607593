import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/mainpage.css';

function MainPage() {
    const navigate = useNavigate();
    const [welcomeMessage, setWelcomeMessage] = useState("Welcome to our booking platform!");

    useEffect(() => {
        // ตัวอย่างการทำงานเมื่อ component ถูกเรนเดอร์
        console.log("MainPage component mounted");

        // คุณสามารถตั้งค่าสถานะอื่นๆ ที่นี่ได้
        // ตัวอย่างการตั้งค่าสถานะข้อความต้อนรับหลังจาก 2 วินาที
        const timer = setTimeout(() => {
            setWelcomeMessage("BOOK NOW AND MAKE EVERY MEETING COUNT");
        }, 2000);

        // เคลียร์ timeout เมื่อ component ถูก unmount
        return () => clearTimeout(timer);
    }, []);

    const handleLoginRedirect = () => {
        navigate('/login');
    };

    return (
        <div className="img">
            <nav className="nav.navbar navbar-light logo-custom"></nav>
            <div className="container d-flex flex-column justify-content-center align-items-center min-vh-100 text-center">
                <div className="row">
                    <div className="all-text col-12">
                        <p className="sarapao">{welcomeMessage}</p>
                        <h1 className="head">GET READY TO MEET!</h1>
                        <p className="sarapao">START BOOKING NOW</p>
                    </div>
                </div>
                <div className="center-button-container">
                    <button className="center-button" onClick={handleLoginRedirect}>Login</button>
                </div>
            </div>
        </div>
    );
}

export default MainPage;
